import React from "react"
import { RouteComponentProps } from "@reach/router"
import {
  useBankAccountMutation,
  useCustomerQuery,
} from "../../../services/smartificateApi"
import LoadingSpinner from "../../LoadingSpinner"
import { useForm, SubmitHandler } from "react-hook-form"
import Input from "../../anmeldung/components/Submit"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"

const Bank = (props: RouteComponentProps) => {
  const { data, error, isLoading, isError } = useCustomerQuery({})
  const [
    updateBankAccount,
    {
      error: mutError,
      isError: isMutError,
      isLoading: isMutLoading,
      isSuccess: isMutSuccess,
    },
  ] = useBankAccountMutation()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>()

  React.useEffect(() => {
    if (data?.bankHolderName) setValue("bankHolderName", data.bankHolderName)
    if (data?.bankIban) setValue("bankIban", data.bankIban)
  }, [data?.bankHolderName, data?.bankIban, setValue])

  type Inputs = {
    bankHolderName: string
    bankIban: string
  }

  const trackChange = () => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || []
      dataLayer.push({
        event: "newIban",
      })
    }
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    updateBankAccount(data)
    trackChange()
  }

  return (
    <>
      {data?.company && (
        <div className="max-w-md mx-auto m-6 p-6 bg-white rounded-md">
          <span className="text-md text-center ">
            <FontAwesomeIcon icon={faInfoCircle} size="1x" className="mr-2" />
            Bitte prüfe ebenfalls Deine Firmendaten im Reiter <b>Firmendaten</b>
            , damit wir Deinen Gutschrift-Beleg ausstellen können.
          </span>
        </div>
      )}
      <div className="mt-12 mx-auto max-w-xs card rounded-md bg-white text-black p-8">
        <h1 className="font-bold text-xl my-1">Bankverbindung</h1>
        {isLoading || isMutLoading ? (
          <LoadingSpinner />
        ) : isError ? (
          <>Fehler! Bitte kontaktiere den Support oder versuche es erneut.</>
        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit)} className="">
              <div className="form-control">
                <label className="label">
                  <span className="label-text font-bold">Kontoinhaber</span>
                </label>
                <input
                  className="input input-bordered"
                  defaultValue={data?.bankHolderName}
                  {...register("bankHolderName", { required: true })}
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text font-bold">IBAN</span>
                </label>
                <input
                  className="input input-bordered"
                  defaultValue={data?.bankIban}
                  {...register("bankIban", { required: true })}
                />
              </div>
              {(errors.bankHolderName || errors.bankIban) && (
                <p className="text-red-500 my-4">
                  Bitte füll beide Felder aus.
                </p>
              )}
              {isMutError && (
                <p className="text-red-500 my-4">
                  {mutError.data.msg
                    ? mutError.data.msg
                    : "Leider ist ein Fehler aufgetreten. Bitte versuche es erneut."}
                </p>
              )}
              {isMutSuccess && (
                <p className="text-green-500 my-4">Erfolgreich gespeichert.</p>
              )}

              <Input
                text={data.bankIban ? "Ändern" : "Speichern"}
                className="w-full"
              />
            </form>
          </>
        )}
      </div>
    </>
  )
}
export default Bank
