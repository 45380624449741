import React, { useState } from "react"
import { RouteComponentProps } from "@reach/router"
import {
  useCustomerAddUserEmailsMutation,
  useCustomerQuery,
} from "../../../services/smartificateApi"
import LoadingSpinner from "../../LoadingSpinner"
import { useForm, SubmitHandler } from "react-hook-form"
import Input from "../../anmeldung/components/Submit"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const AddUserEmails = (props: RouteComponentProps) => {
  const { data, isLoading, isError } = useCustomerQuery({})
  const [addUserEmails, setAddUserEmails] = useState<string[]>([])

  const [
    updateAddUserEmails,
    {
      error: mutError,
      isError: isMutError,
      isLoading: isMutLoading,
      isSuccess: isMutSuccess,
    },
  ] = useCustomerAddUserEmailsMutation()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>()

  React.useEffect(() => {
    if (data?.addUserEmails) setAddUserEmails(data.addUserEmails)
  }, [data?.addUserEmails])

  // React.useEffect(() => {
  //   updateAddUserEmails({ addUserEmails: addUserEmails })
  // }, [addUserEmails, updateAddUserEmails])

  type Inputs = {
    newAddEmail: string
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const newVal = [...addUserEmails, data.newAddEmail]
    updateAddUserEmails({
      addUserEmails: newVal,
    })
    setValue("newAddEmail", "")
    setAddUserEmails(newVal)
  }

  return (
    <div className="mt-12 mx-auto max-w-xl card rounded-md bg-white text-black p-8">
      <h1 className="font-bold text-xl my-1">Unterkonten</h1>
      <p className="">
        Die folgenden E-Mail Addressen können sich ebenfalls auf dieses Konto
        einloggen.
      </p>
      {isLoading || isMutLoading ? (
        <LoadingSpinner />
      ) : isError ? (
        <>Fehler! Bitte kontaktiere den Support oder versuche es erneut.</>
      ) : (
        <>
          <Table className="table w-full md:table-zebra table-compact md:px-2 rounded-md">
            <Thead>
              <Tr className="border-b-2 border-secondary">
                <Th className="bg-white">E-Mail Adresse</Th>
                <Th className="bg-white"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {addUserEmails.map((mail) => (
                <Tr>
                  <Td>{mail}</Td>
                  <Td>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      size="1x"
                      className="mr-2 text-red-500 cursor-pointer"
                      onClick={() => {
                        const newVal = addUserEmails.filter((e) => e !== mail)
                        updateAddUserEmails({
                          addUserEmails: newVal,
                        })
                        setAddUserEmails(newVal)
                      }}
                    />
                  </Td>
                </Tr>
              ))}
              {addUserEmails.length < 1 && (
                <Tr>
                  <Td>keine Einträge</Td>
                  <Td></Td>
                </Tr>
              )}
            </Tbody>
          </Table>
          <h1 className="font-bold text-md mt-6">Weitere Adresse hinzufügen</h1>
          <form onSubmit={handleSubmit(onSubmit)} className="">
            {/* hack so hubspot ignores the form */}
            <input
              type="hidden"
              name="amex"
              required={false}
              value=""
              id="amex"
            ></input>
            <div className="form-control mt-4">
              <input
                className="input input-bordered"
                {...register("newAddEmail", {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Ungültige E-Mail Adresse",
                  },
                })}
                placeholder="E-Mail Adresse"
              />
            </div>

            {errors.newAddEmail && (
              <p className="text-red-500 my-4">
                Bitte gib eine E-Mail Adresse ein.
              </p>
            )}
            {isMutError && (
              <p className="text-red-500 my-4">
                {mutError.data.msg
                  ? mutError.data.msg
                  : "Leider ist ein Fehler aufgetreten. Bitte versuche es erneut."}
              </p>
            )}
            {isMutSuccess && (
              <p className="text-green-500 my-4">Erfolgreich gespeichert.</p>
            )}

            <Input text={"Hinzufügen"} className="w-full" />
          </form>
        </>
      )}
    </div>
  )
}
export default AddUserEmails
