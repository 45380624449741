import React, { useMemo } from "react"
import { RouteComponentProps } from "@reach/router"
import {
  useCustomerQuery,
  useToggleTaxFreeModeMutation,
} from "../../../services/smartificateApi"
import { scrollToTop } from "../../../helpers/ScrollTo"
import {
  faCreditCard,
  faCalendarAlt,
  faInfoCircle,
  faCar,
  faFileInvoice,
  faBalanceScaleRight,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LoadingSpinner from "../../LoadingSpinner"
import { Link } from "gatsby"
import Collapsible from "../../Collapsible"
import PayoutProblemNotice from "../components/PayoutProblemNotice"

const toEuro = (value: number | undefined): string => {
  const numVal = value ? value : 0
  return numVal.toLocaleString("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
  })
}

const Auszahlungen = (props: RouteComponentProps) => {
  const { data, error, isLoading, refetch } = useCustomerQuery({})
  const [
    toggleTaxFreeMode,
    {
      error: mutError,
      isError: isMutError,
      isLoading: isMutLoading,
      isSuccess: isMutSuccess,
    },
  ] = useToggleTaxFreeModeMutation()
  React.useEffect(() => {
    refetch()
  }, [])

  const nextPayout = useMemo(() => {
    if (!data) return null
    return data.nextPayout !== null
      ? data.nextPayout
      : data.nextYearPayout
      ? data.nextYearPayout
      : null
  }, [data])

  return (
    <>
      {!isLoading && !isMutLoading ? (
        <>
          <div className="my-12 pb-12 grid gap-4 grid-cols-1" id="auszahlung">
            {nextPayout !== null ? (
              <div className="">
                <div className="mx-auto">
                  <span className="block text-xl text-white font-bold text-center mb-4">
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      size="1x"
                      className="mr-2"
                    />
                    Nächste Auszahlung
                  </span>
                </div>
               
                {data && data.payoutProblemReminderDate && (
                 <PayoutProblemNotice />
                )}
                {nextPayout &&
                  nextPayout.payout?.dealDetails &&
                  nextPayout.payout?.dealDetails.length > 0 && (
                    <div className="overflow-x-auto p-6 bg-white rounded-md">
                      <span className="font-bold mt-4 text-center mb-2">
                        <FontAwesomeIcon
                          icon={faCar}
                          size="1x"
                          className="mr-2"
                        />
                        Bestätigte Fahrzeuge
                      </span>
                      <table className="table w-full table-zebra table-compact">
                        <thead>
                          <tr className="border-b-2 border-secondary">
                            <th className="bg-white">Vorgangsnummer</th>
                            <th className="bg-white">Kennzeichen</th>
                            {/* <th className="bg-white">Rate</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {nextPayout.payout?.dealDetails.map((pod) => (
                            <tr>
                              <td>SMT-{pod.dealId}</td>
                              <td>{pod.regCertRegPlate}</td>
                              {/* <td>{toEuro(pod.rateNet)}</td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                <div className="mt-4">
                  <div className="grid md:grid-cols-2 gap-2">
                    <div className="p-6 bg-white rounded-md flex flex-col">
                      <span className="font-bold">
                        <FontAwesomeIcon
                          icon={faCreditCard}
                          size="1x"
                          className="mr-2"
                        />
                        Auszahlungsmethode
                      </span>
                      <span className="mt-1">SEPA-Überweisung</span>
                      {!data?.bankIban && (
                        <Link to="/kundenbereich/konto/bankverbindung">
                          <span className="mt-1 text-primary">
                            <FontAwesomeIcon
                              icon={faExclamationCircle}
                              size="1x"
                              className="mr-2"
                            />
                            Bankdaten fehlen
                          </span>
                        </Link>
                      )}
                      {/* <>
                        <span className="font-bold mt-4">
                          <FontAwesomeIcon
                            icon={faCalendarAlt}
                            size="1x"
                            className="mr-2"
                          />
                          Zeitpunkt
                        </span>
                        <span className="mt-1">
                          voraussichtlich {nextPayout?.expectedDateString}
                        </span>
                      </> */}
                    </div>
                    <div className="p-6 bg-white rounded-md grid grid-cols-1 space-y-2">
                      <span className="font-bold">
                        <FontAwesomeIcon
                          icon={faFileInvoice}
                          size="1x"
                          className="mr-2"
                        />
                        Zusammenfassung
                      </span>
                      {/* disabled */}
                      {nextPayout.payout?.taxFreeMode && false ? (
                        <span>Steueroptimierte Auszahlung</span>
                      ) : (
                        <>
                          <div className="grid grid-cols-2">
                            <span className="">Raten Gesamt</span>
                            <span>{toEuro(nextPayout.payout?.ratesNet)}</span>
                          </div>
                          {nextPayout.payout?.oneTimeBonus && (
                            <div className="grid grid-cols-2">
                              <span className="">Bonus</span>
                              <span>
                                {toEuro(nextPayout.payout?.oneTimeBonus)}
                              </span>
                            </div>
                          )}
                          {nextPayout.payout?.referralBonus && (
                            <div className="grid grid-cols-2">
                              <span className="">Freunde-Bonus</span>
                              <span>
                                {toEuro(nextPayout.payout?.referralBonus)}
                              </span>
                            </div>
                          )}
                        </>
                      )}
                      {nextPayout.payout?.totalVat && (
                        <>
                          <div className="grid grid-cols-2 border-t-2 pt-2">
                            <span className="">Gesamt (netto)</span>
                            <span>{toEuro(nextPayout.payout?.totalNet)}</span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="">zzgl. 19% USt.</span>
                            <span>{toEuro(nextPayout.payout?.totalVat)}</span>
                          </div>
                        </>
                      )}
                      <div className="grid grid-cols-2 border-t-2 pt-2">
                        <span className="font-bold">Gesamt</span>
                        <span>
                          {nextPayout.payout?.totalGross
                            ? toEuro(nextPayout.payout?.totalGross)
                            : toEuro(nextPayout.payout?.totalNet)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mx-auto m-6 p-6 bg-white rounded-md">
                <span className="text-md text-center ">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    size="1x"
                    className="mr-2"
                  />
                  Aktuell stehen keine weiteren Auszahlungen an.
                </span>
              </div>
            )}
            {data?.invoices && data?.invoices.length > 0 && (
              <div className="mx-auto mt-6">
                <span className="block text-center text-xl text-white font-bold mb-4">
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    size="1x"
                    className="mr-2"
                  />
                  Bisherige Auszahlungen
                </span>

                {data?.invoices.map((invoice) => (
                  <div className="mx-auto m-6 p-6 bg-white rounded-md">
                    {/* <span className="text-md text-center ">{year}</span> */}
                    <a
                      href={invoice.url}
                      className="font-bold hover:underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Gutschrift {invoice.invoiceNumber}
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}
export default Auszahlungen
