import React from "react"
import { useLocation } from "@reach/router"
import {
  faUser,
  faSignOutAlt,
  faHome,
  faBell,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, navigate } from "gatsby"
import { useAppDispatch } from "../../state/createStore"
import { setAuthState } from "../../state/authSlice"
import {
  useCustomerQuery,
} from "../../services/smartificateApi"
import Button from "../anmeldung/components/Button"
import { anmeldungPageNames } from "../anmeldung/anmeldungRouter"

const Layout = (props: { children?: JSX.Element | JSX.Element[] }) => {
  const [skip, setSkip] = React.useState(true)
  const [cid, setCid] = React.useState("")
  const { data: customerData } = useCustomerQuery({})

  React.useEffect(() => {
    if (customerData) {
      setCid(customerData.id)
      setSkip(false)
    }
  }, [customerData])

  const pathname = useLocation().pathname
  const dispatch = useAppDispatch()
  return (
    <>
      <div className="px-1 md:px-6">
        <div className="py-4 flex ">
          <ul className="menu rounded bg-white items-stretch  shadow-lg horizontal rounded-box mx-auto">
            <li className={pathname.includes("start") ? `bordered` : ``}>
              <Link to="/kundenbereich/start" className="pl-2 pr-2 sm:px-4">
                <FontAwesomeIcon icon={faHome} size="1x" className="mr-2" />
                Start
              </Link>
            </li>
            <li className={pathname.includes("konto") ? `bordered` : ``}>
              <Link to="/kundenbereich/konto" className="px-2 sm:px-4">
                <FontAwesomeIcon icon={faUser} size="1x" className="mr-2" />
                Konto
              </Link>
            </li>

            <li className={``}>
              <Link
                to="/"
                className="pl-2 pr-2 sm:px-4"
                onClick={() => dispatch(setAuthState("LoggedOut"))}
              >
                <FontAwesomeIcon
                  icon={faSignOutAlt}
                  size="1x"
                  className="mr-2"
                />
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="px-4 md:px-6">{props.children}</div>
    </>
  )
}
export default Layout
