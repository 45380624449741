import React from "react"
import { Router, Redirect } from "@reach/router"
import Home from "../../components/kundenbereich/Home"
import Login from "../../components/kundenbereich/Login"
import { PageProps } from "gatsby"
import { useSelector } from "react-redux"
import { RootState } from "../../state/createStore"
import { isLoggedIn } from "../../state/authSlice"
import FromEmail from "../../components/kundenbereich/FromEmail"
import BasicPageGreen from "../../components/PageWrapper/BasicPageGreen"
import Konto from "../../components/kundenbereich/Konto/Konto"
const App = (props: PageProps) => {
  const state = useSelector((state: RootState) => state)

  return (
    <BasicPageGreen title={"Kundenbereich"} location={props.location}>
      <div className="min-h-screen max-w-screen-md mx-auto">
        <Router basepath="/kundenbereich">
          <FromEmail path="/token" />
          {isLoggedIn(state) ? (
            <>
              <Home path="/start" />
              <Konto path="/konto/*" />
              <Redirect from="/" to="/kundenbereich/start" noThrow={true} />
              <Redirect
                from="/login"
                to="/kundenbereich/start"
                noThrow={true}
              />
              <Redirect
                from="/signup"
                to="/kundenbereich/start"
                noThrow={true}
              />
              <Redirect
                from="/login"
                to="/kundenbereich/start"
                noThrow={true}
              />
            </>
          ) : (
            <>
              <Login path="/login" default />
              <Login adminMode={true} path="/loginAdmin" />
            </>
          )}
        </Router>
      </div>
    </BasicPageGreen>
  )
}
export default App
