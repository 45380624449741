import * as React from "react"
import { RouteComponentProps } from "@reach/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";


export const PayoutProblemNotice = (props: RouteComponentProps) => {

  return (  <div className="mx-auto m-6 p-6 bg-white rounded-md">
  <span className="text-md text-center font-bold text-red-500">
    <FontAwesomeIcon
      icon={faInfoCircle}
      size="1x"
      className="mr-2"
    />
    Wichtig
  </span>
  <br />
  <span className="text-md text-center">
    Wir haben Dich bereits via E-Mail kontaktiert, da uns für
    die Auszahlung Deiner THG-Quote noch etweder Deine
    Bankdaten oder Firmenandaten fehlen. Bitte ergänze diese
    hier im Kundenbereich, um Deine Auszahlung zu erhalten.
    Bitte kontaktiere uns anschließend unter support@smartificate.de
  </span>
</div>

  )
}

export default PayoutProblemNotice
