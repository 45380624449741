import React, { useMemo, useState } from "react"
import { RouteComponentProps } from "@reach/router"
import {
  useCustomerQuery,
} from "../../../services/smartificateApi"
import {
  DealStage,
  getDealStageName,
} from "../../../apiHelpers/types"
import {
  faCar,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LoadingSpinner from "../../LoadingSpinner"
import Button from "../../anmeldung/components/Button"
import { navigate } from "gatsby-link"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

const Autos = (props: RouteComponentProps) => {
  const { data, isLoading, refetch } = useCustomerQuery({})

   React.useEffect(() => {
    refetch()
  }, [])

  
  const customerCarsFiltered = useMemo(() => {
    if (!data) return []
    return data.deals.filter((x) => x.dealStage !== DealStage.Lost)
  }, [data])


  return (
    <>
      {!isLoading ? (
        <>
          <div className="my-12 pb-12 grid gap-4 grid-cols-1">
           
           
            {customerCarsFiltered.length > 0 && (
              <>
                <div className="bg-white rounded-md">
                  <div className="mx-auto w-auto mt-2 text-center mb-2">
                    <span className="font-bold">
                      <FontAwesomeIcon
                        icon={faCar}
                        size="1x"
                        className="mr-2"
                      />
                      Eigene Fahrzeuge
                    </span>
                  </div>
                  <Table className="table w-full md:table-zebra table-compact md:px-2 rounded-md">
                    <Thead>
                      <Tr className="border-b-2 border-secondary">
                        <Th className="bg-white normal-case">Vorgangsnummer</Th>
                        <Th className="bg-white normal-case">Kennzeichen</Th>
                        <Th className="bg-white normal-case">Restlaufzeit</Th>
                        <Th className="bg-white normal-case">Rate</Th>
                        <Th className="bg-white normal-case">Status</Th>
                        <Th className="bg-white normal-case"></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {customerCarsFiltered.map((deal) => (
                        <Tr>
                          <Td>SMT-{deal.dealId}</Td>
                          <Td>{deal.registrationPlate}</Td>
                          <Td>
                            {deal.remContractYears !== undefined ? (
                              <>
                                {deal.remContractYears}{" "}
                                {deal.remContractYears &&
                                deal.remContractYears === 1
                                  ? "Jahr"
                                  : "Jahre"}
                              </>
                            ) : (
                              "-"
                            )}
                          </Td>
                          <Td>
                            {deal.rate2023
                              ? deal.rate2023 + "€"
                              : deal.rate
                              ? deal.rate + "€"
                              : "-"}
                          </Td>

                          <Td>{getDealStageName(deal.dealStage)}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}
export default Autos
