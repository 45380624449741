export type LeadChannel = {
  channel: string
  rateOptions: RateOptions
  utm: string
  referralFrom?: string
  dealerId?: string
  emid?: string
  puid?: string
  subid?: string
  display?: string
  displayShort?: string
  displayFrontpage?: string
  displayContractPage?: string
  bonus?: number
  agbFilename?: string
}
export type RateOptions = {
  rateOptionsName: string
  options: RateOption[] // allowed range: 1-3 items
  defaultRateIdx: number
  bonusMode: boolean // Show the optional Bonus in calculation instead of increasing rate per year
  flexMode?: boolean
}
export type RateOption = {
  years: number
  rate: number
  categoryRates?: { [key in RegCertCarCategory]: number }
  bonus?: number
}
export const rateOptiontoRate = (
  rateOption: RateOption,
  regCertCarCategory?: RegCertCarCategory
) => {
  if (
    regCertCarCategory &&
    rateOption.categoryRates &&
    regCertCarCategory in rateOption.categoryRates
  )
    return rateOption.categoryRates[regCertCarCategory]
  else if (
    rateOption.categoryRates &&
    ("Other" as RegCertCarCategory) in rateOption.categoryRates
  )
    return rateOption.categoryRates["Other" as RegCertCarCategory]
  else return rateOption.rate
}

export type Customer = {
  id: string
  firstName: string
  lastName: string
  email: string
  addUserEmails?: string[]
  company?: string
  companyData?: CompanyData
  bankHolderName?: string
  bankIban?: string
  referralOwn: string
  referralFrom?: string
  referralFromBonusUnpayed: number
  referralToBonusUnpayed: number
  referralBonusPaid: number
  taxFreeMode: boolean
  registrationDate?: number
  deals: Deal[]
  dealerLeadChannel?: LeadChannel
  dealerDefaultCommission?: DealerCommission
  nextPayout: { payout: Payout; expectedDateString: string } | null //next potential Payout preview
  nextYearPayout: { payout: Payout; expectedDateString: string } | null //next year potential Payout preview
  payoutHistory?: Payout[]
  invoices: { invoiceNumber: string; url: string }[]
  payoutProblemReminderDate?: Date
}

export type Payout = {
  fullVersionKey?: string
  payoutStage: PayoutStage
  bankDate?: Date
  taxFreeMode: boolean
  ratesNet?: number // only on deal dealDetails
  totalNet: number // calculated: all dealDetails + referralbonus
  totalGross?: number // VAT only on business customers, calculated: totalNet * 1.19
  totalVat?: number // VAT only on business customers, , calculated: totalNet * 0.19
  referralBonus?: number
  oneTimeBonus?: number // calculated: sum of all bonus in dealDetails, ignored in invoice generation, as it uses the actual values
  dealDetails: PayoutDetails[] // either this or manualDetails, not both
  manualDetails: DealManualDetails[] // either this or dealDetails, not both
  billingData?: BillingData
}
export type PayoutStage =
  | "1.PayoutReady"
  | "2.PayoutDone"
  | "3.PayoutEmailDone"
  | "8.NoPayment" // Used for "TextCorrection", both for Storno and corrected invoice
  | "9.Refund" // Stornogutschrift that has been refunded
export type BillingData = {
  contactId: string
  firstName: string
  lastName: string
  invoiceNumber: string
  invoiceType?: InvoiceType
  invoiceDate: Date
  fullfillmentYear?: string
  bankHolderName: string
  bankIban: string
  company?: string
  companyData?: CompanyData
}
export type InvoiceType =
  | {
      invoiceTypeName: "Gutschrift"
      paymentText?: string
      relatedInvoiceNumbers?: string[] // to send with email of new invoice
    }
  | {
      invoiceTypeName: "Stornogutschrift"
      referencingInvoiceNumber: string // reference in header line
      paymentText?: string
    }

export type NewInvoiceBillingType =
  | "TextCorrection" // Old invoice was paid, only text correction
  | "OpenPayout" // Old Invoice was not paid
  | "OpenPayoutAfterRefund" // Old invoice is paid back, new one open for payment
  | "Cancelation" // Old invoice is paid back, no new one

export type PayoutDetails = {
  rateNet: number
  bonus: Bonus[]
  regCertRegPlate: string
  regCertFin: string
  regCertFirstName: string
  regCertLastName: string
  regCertStreet?: string
  regCertZip?: string
  regCertCity?: string
  dealId: string
}
export type DealManualDetails = {
  description: string
  amountNet: number
}
export type Bonus = {
  name: string
  amount: number
}
export type CompanyData = {
  companyStreet: string
  companyZip: string
  companyCity: string
  companyAddressAddition?: string
  companyVATFree: boolean
  companyVAT?: string
}

export type Deal = {
  dealId: string
  website: string
  dealStage: string
  payedOutYears: string[]
  brand: string
  registrationPlate: string
  regCertFin: string
  regCertFirstName?: string
  regCertLastName?: string
  regCertCarCategory?: RegCertCarCategory
  rate?: number
  rate2023?: number
  remContractYears?: number
  remContractYearNumbers?: number[] // e.g. [2021, 2022]
  bonus1Name?: string
  bonus2Name?: string
  bonus1Value?: number
  bonus2Value?: number
  bonusPaid: number
  ubaMonthName: string // Month where the Payout is expected
  whitelabelPartner?: string
  leadChannel: LeadChannel
  verifiedContractDate?: Date
  dealerId?: string
  dealerCommission?: DealerCommission
  dealerCommissionPayedOutYears: number[]
  dealerCommissionPayedOutDates: Date[]
  dealerCommissionPayedOutInvoices: string[]
  dealerCustomField?: string
  ubaConfirmationYears: string[]
  ubaRequestedYears: string[]
  regCertReconfirmationDate: Date
  signedContractDate?: Date
  payoutProblemType?: PayoutProblemType
  signedForPreviousYear: boolean
}

export type DealerCommission = {
  // per year as key
  year1?: number
  year2?: number
  year3?: number
}

export type DealerDeal = {
  dealId: string
  dealStage: string
  signedContractDate?: Date
  registrationPlate?: string
  regCertFin?: string
  dealerCustomField?: string
  dealerCommissionPayedOutYears: string[]
  dealerCommissionPayedOutDates: Date[]
  dealerCommissionPayedOutInvoices: string[]
}
export enum DealStage {
  Registriert = "19148754",
  Fahrzeugschein = "19148755",
  Signed = "19148756",
  SignedComplicated = "36256204",
  Verified = "19148759",
  ChangesRequested = "19148757",
  ChangesToDo = "19931324",
  Lost = "19148760",
  UBAReady = "31940287",
  UBARejected = "46094547",
  PartnerDone = "61745396",
  SignedNextYear = "65689049",
  RunningContract = "133751253",
}

export enum whitelabelPartner {
  tibber = "4936901",
  emilfrey = "4937401",
  ksr = "4938501",
  wechselpilot = "2703419",
  "neuwagen-online" = "595286",
  "flensburg-energie" = "392114",
  nordsw = "4847651",
}
export enum PayoutProblemType {
  IbanInvalid = "IBAN invalid",
  IbanRejected = "IBAN rejected by bank",
  IbanMissing = "IBAN missing",
  CompanyDetailsMissing = "Company details missing",
  InvoiceIncorrectAmount = "Invoice Incorrect Amount",
  DealMissingValue = "Deal Missing Value",
}

export const isPayoutNextYearDeal = (deal: Deal): boolean =>
  [
    DealStage.SignedNextYear,
    // @ts-ignore
  ].includes(deal.dealStage) &&
  deal.remContractYears !== undefined &&
  deal.remContractYears > 0

// All stages that count as a done lead for which affiliates get their kickback
export const isFinallyConfirmedStage = (stage: string): boolean =>
  [
    DealStage.RunningContract,
    // @ts-ignore
  ].includes(stage)

export type DealStageFrontend =
  | "Fahrzeugschein fehlt"
  | "THG-Abtretung fehlt"
  | "In Prüfung"
  | "Änderungen in Bearbeitung"
  | "Widerrufen"
  | "Antrag vollständig"
  | "Ans Umweltbundesamt übermittelt"
  | "Vom Umweltbundesamt bestätigt"
  | "In Auszahlung"
  | "Problem bei der Auszahlung"
  | "Ausgezahlt"
  | "Abrechnung über Partner"
  | "Laufender Vertrag"

export const dealStagesFrontend: DealStageFrontend[] = [
  "Fahrzeugschein fehlt",
  "THG-Abtretung fehlt",
  "In Prüfung",
  "Änderungen in Bearbeitung",
  "Widerrufen",
  "Antrag vollständig",
  "Ans Umweltbundesamt übermittelt",
  "Vom Umweltbundesamt bestätigt",
  "In Auszahlung",
  "Problem bei der Auszahlung",
  "Ausgezahlt",
  "Abrechnung über Partner",
  "Laufender Vertrag",
]

export const dealStageFrontendToDealStage = (
  dsf: DealStageFrontend
): DealStage[] => {
  switch (dsf) {
    case "Fahrzeugschein fehlt":
      return [DealStage.Registriert]
    case "THG-Abtretung fehlt":
      return [DealStage.Fahrzeugschein]
    case "In Prüfung":
      return [DealStage.Signed, DealStage.SignedComplicated]
    case "Änderungen in Bearbeitung":
      return [
        DealStage.ChangesRequested,
        DealStage.ChangesToDo,
        DealStage.UBARejected,
      ]
    case "Widerrufen":
      return [DealStage.Lost]
    case "Antrag vollständig":
      return [DealStage.UBAReady]
    case "Ans Umweltbundesamt übermittelt":
      return [DealStage.RunningContract]
    case "Vom Umweltbundesamt bestätigt":
      return [DealStage.RunningContract]
    case "In Auszahlung":
      return [DealStage.RunningContract]
    case "Problem bei der Auszahlung":
      return [DealStage.RunningContract]
    case "Ausgezahlt":
      return [DealStage.RunningContract]
    case "Abrechnung über Partner":
      return [DealStage.PartnerDone]
    case "Laufender Vertrag":
      return [DealStage.RunningContract]
  }
}

export const getDealStageName = (dealStage: string): DealStageFrontend => {
  switch (dealStage) {
    case DealStage.Registriert: // Registriert
      return "Fahrzeugschein fehlt"
    case DealStage.Fahrzeugschein: // Fahrzeugschein
      return "THG-Abtretung fehlt"
    case DealStage.Signed: // Signed
    case DealStage.SignedComplicated: // SignedComplicated
      return "In Prüfung"
    case DealStage.ChangesRequested: // Changes Requested
    case DealStage.ChangesToDo: // Changes ToDo
    case DealStage.UBARejected:
      return "Änderungen in Bearbeitung"
    case DealStage.Lost: // Lost
      return "Widerrufen"
    case DealStage.UBAReady: // UBAReady
      return "Antrag vollständig"
    case DealStage.RunningContract:
      return "Laufender Vertrag"
    case DealStage.PartnerDone:
      return "Abrechnung über Partner"
    default:
      return "Antrag vollständig"
  }
}

export type RegCertCarCategory = "M1" | "N1" | "M3" | "Scooter" | "Other"

export const toRegCertCarCategory = (
  carCategory: string
): RegCertCarCategory => {
  switch (carCategory) {
    case "M1":
      return "M1"
    case "N1":
      return "N1"
    case "M3":
      return "M3"
    case "Scooter":
      return "Scooter"
    default:
      return "Other"
  }
}

export enum RateToken {
  twofifty = "6a6de99fa71aa44f25c132bd7c3e8c22250",
  twoeighty = "01fd5cf4264c0abb033d41761e38871a280",
  threehundred = "b88345f605db794e9384c56ba7e41e18300",
  threefifity = "4d4fd2224b1b9ce9e7d5s59fc8e9akdf350",
}

export const rateTokenToRate = (rt: RateToken): number | false => {
  switch (rt) {
    case RateToken.twofifty:
      return 250
    case RateToken.twoeighty:
      return 280
    case RateToken.threehundred:
      return 300
    case RateToken.threefifity:
      return 350
    default:
      return false
  }
}

export type ApiDealConfirmRegcerts = {
token: string
toConfirmDeals?: string[]
}
