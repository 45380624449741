import React from "react"
import { RouteComponentProps } from "@reach/router"
import Layout from "../Layout"
import { Router, Redirect } from "@reach/router"
import Firmendaten from "./Firmendaten"
import Bank from "./Bank"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import {
  faCreditCard,
  faBuilding,
  faCar,
  faEuroSign,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useCustomerQuery } from "../../../services/smartificateApi"
import Autos from "./Autos"
import Auszahlungen from "./Auszahlungen"
import AddUserEmails from "./AddUserEmails"

const Konto = (props: RouteComponentProps) => {
  const { data } = useCustomerQuery({})
  const pathname = useLocation().pathname
  return (
    <Layout>
      <>
        <div className="px-1 md:px-6">
          <div className="py-4 flex ">
            <ul className="space-y-4 p-4 md:flex md:space-y-0 md:py-3 rounded bg-white items-stretch  shadow-lg horizontal rounded-box mx-auto">
              <li
                className={`pb-1 ${
                  pathname.includes("autos")
                    ? `bordered border-b-4 border-primary font-bold`
                    : ``
                }`}
              >
                <Link
                  to="/kundenbereich/konto/autos"
                  className="px-2 sm:px-4 sm:flex sm:flex-col sm:items-center "
                >
                  <FontAwesomeIcon
                    icon={faCar}
                    size="1x"
                    className="mr-2 w-8 sm:mx-0"
                  />
                  Fahrzeuge
                </Link>
              </li>
              <li
                className={`pb-1 ${
                  pathname.includes("auszahlungen")
                    ? `bordered border-b-4 border-primary font-bold`
                    : ``
                }`}
              >
                <Link
                  to="/kundenbereich/konto/auszahlungen"
                  className="px-2 sm:px-4 sm:flex sm:flex-col sm:items-center "
                >
                  <FontAwesomeIcon
                    icon={faEuroSign}
                    size="1x"
                    className="mr-2 w-8 sm:mx-0"
                  />
                  Auszahlungen
                </Link>
              </li>
              <li
                className={`pb-1 ${
                  pathname.includes("bankverbindung")
                    ? `bordered border-b-4 border-primary font-bold`
                    : ``
                }`}
              >
                <Link
                  to="/kundenbereich/konto/bankverbindung"
                  className="px-2 sm:px-4 sm:flex sm:flex-col sm:items-center "
                >
                  <FontAwesomeIcon
                    icon={faCreditCard}
                    size="1x"
                    className="mr-2 w-8 sm:mx-0"
                  />
                  IBAN
                </Link>
              </li>
              {data?.company && (
                <li
                  className={`pb-1 ${
                    pathname.includes("firmendaten")
                      ? `bordered border-b-4 border-primary font-bold`
                      : ``
                  }`}
                >
                  <Link
                    to="/kundenbereich/konto/firmendaten"
                    className="px-2 sm:px-4 sm:flex sm:flex-col sm:items-center "
                  >
                    <FontAwesomeIcon
                      icon={faBuilding}
                      size="1x"
                      className="mr-2 w-8 sm:mx-0"
                    />
                    Firmendaten
                  </Link>
                </li>
              )}
              {data?.dealerLeadChannel && false && (
                <li
                  className={`pb-1 ${
                    pathname.includes("unterkonten")
                      ? `bordered border-b-4 border-primary font-bold`
                      : ``
                  }`}
                >
                  <Link
                    to="/kundenbereich/konto/unterkonten"
                    className="px-2 sm:px-4 sm:flex sm:flex-col sm:items-center "
                  >
                    <FontAwesomeIcon
                      icon={faUserPlus}
                      size="1x"
                      className="mr-2 w-8"
                    />
                    Unterkonten
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>

        <Router basepath="/kundenbereich/konto">
          <Autos path="/autos" />
          <Auszahlungen path="/auszahlungen" />
          <Bank path={"/bankverbindung"} />
          <Firmendaten path="/firmendaten" />
          <AddUserEmails path="/unterkonten" />
          <Bank path={"/bankverbindung"} />
          <Redirect from="/" to="/kundenbereich/konto/autos" noThrow={true} />
        </Router>
      </>
    </Layout>
  )
}
export default Konto
