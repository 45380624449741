import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import Pusher from "pusher-js"
// import {
//   Input,
//   InputGroup,
//   InputRightElement,
//   VStack,
//   Button,
//   Divider,
//   Center,
//   Box,
//   useToast,
// } from '@chakra-ui/react'
import { useSelector } from "react-redux"
import { setAuthState, User } from "../../state/authSlice"

import { useLoginMutation } from "../../services/smartificateApi"
import Button from "../anmeldung/components/Button"
import { RootState, useAppDispatch } from "../../state/createStore"
import SmartificateIcon from "../../images/favicon.svg"
import NewMailIcon from "../../images/new_mail.svg"
import { navigate } from "gatsby"
import LoadingSpinner from "../LoadingSpinner"
import queryString from "query-string"
import { SubmitHandler, useForm } from "react-hook-form"
import Submit from "../anmeldung/components/Submit"
import { TokenRequest } from "../../services/types/tokenRequest"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"

export const Login = (props: RouteComponentProps<{ adminMode: boolean }>) => {
  const dispatch = useAppDispatch()
  const [login, { isLoading, isError, error: loginError }] = useLoginMutation()
  const authState = useSelector((state: RootState) => state.auth)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TokenRequest>()

  React.useEffect(() => {
    const utmParams = props?.location?.search
      ? queryString.parse(props.location.search)
      : {}
    if (
      utmParams.email &&
      utmParams.email !== "" &&
      utmParams.adminEmail &&
      utmParams.adminEmail !== ""
    ) {
      console.log("Admin login mode")
      doLogin({
        email: decodeURIComponent(utmParams.email.toString()),
        adminEmail: decodeURIComponent(utmParams.adminEmail.toString()),
      })
    } else if (utmParams.email) {
      doLogin({ email: decodeURIComponent(utmParams.email.toString()) })
    }
  }, [])

  const onSubmit: SubmitHandler<TokenRequest> = (data) => {
    doLogin({
      ...data,
    })
  }

  const doLogin = async ({ email, adminEmail }: TokenRequest) => {
    login({ email, adminEmail })
      .unwrap()
      .then((data) => {
        dispatch(setAuthState("Waiting"))
        if (data.channel && data.channelPw) {
          console.log("listening on", data.channel)
          var pusher = new Pusher("02f4636a0abd59168bab", {
            cluster: "eu",
            authEndpoint: "/.netlify/functions/pusherAuth",
            auth: { params: { channelPw: data.channelPw } },
          })
          var channel = pusher.subscribe(data.channel)
          channel.bind("login", (data) => {
            // Method to be dispatched on trigger.
            console.log("pusher!", data)
            const user: User = { token: data.token, userType: "customer" }
            dispatch(setAuthState(user))
            navigate(`/kundenbereich/start`)
          })
        }
      })
      .catch(() => {})
  }


  React.useEffect(() => {
    console.log("loginError", loginError )
    if (loginError  && (loginError as FetchBaseQueryError).status === 401) {
      console.log("redirecting to external url")
      window.location.href = "https://smartificate.de/login";
    }
  }, [loginError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* hack so hubspot ignores the form */}
      <input
        type="hidden"
        name="amex"
        required={false}
        value=""
        id="amex"
      ></input>
      <div className="mt-12 mx-auto max-w-xs card rounded-md bg-white text-black p-8">
        <SmartificateIcon className="max-h-8" />
        <h1 className="card-title text-center text-2xl">Login</h1>
        {(isLoading || authState === "Waiting") && !isError ? (
          isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <NewMailIcon className="max-h-36" />
              <h2 className="card-title text-center text-2xl">
                Schau in Dein Postfach!
              </h2>
              <p className="mb-0">
                <b>Wir haben Dir eine E-Mail geschickt.</b> In dieser befindet
                sich ein Link. Wenn Du diesen klickst, wirst Du automatisch hier
                eingeloggt.
              </p>
            </>
          )
        ) : (
          <>
            <div className="form-control">
              <label className="label">
                <span className="label-text font-bold">E-Mail</span>
              </label>
              <input
                className="input input-primary input-transparent text-base-content mb-2"
                placeholder="E-Mail"
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Ungültige E-Mail Adresse",
                  },
                })}
              />
            </div>
            {props.adminMode && (
              <div className="form-control">
                <label className="label">
                  <span className="label-text font-bold">Admin E-Mail</span>
                </label>
                <input
                  className="input input-primary input-transparent text-base-content mb-2"
                  placeholder="Admin E-Mail"
                  {...register("adminEmail", {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Ungültige E-Mail Adresse",
                    },
                  })}
                />
              </div>
            )}
            <Submit text="Login" className="w-full m-0" />

            {isError && (
              <>
                <p className="text-red-500 my-4">
                  Fehler! Hast du noch kein Konto?
                </p>
              </>
            )}
          </>
        )}
      </div>
    </form>
  )
}

export default Login
