import React from "react"
import { RouteComponentProps } from "@reach/router"
import {
  useCompanyMutation,
  useCustomerQuery,
} from "../../../services/smartificateApi"
import LoadingSpinner from "../../LoadingSpinner"
import { useForm, SubmitHandler } from "react-hook-form"
import Input from "../../anmeldung/components/Submit"
import { Company } from "../../../services/types/company"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
const Firmendaten = (props: RouteComponentProps) => {
  const { data, isLoading, isError } = useCustomerQuery({})
  const [
    updateCompany,
    {
      error: mutError,
      isError: isMutError,
      isLoading: isMutLoading,
      isSuccess: isMutSuccess,
    },
  ] = useCompanyMutation()
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<Company>()

  React.useEffect(() => {
    if (data?.company) setValue("company", data.company)
    if (data?.companyData?.companyStreet)
      setValue("companyStreet", data.companyData?.companyStreet)
    if (data?.companyData?.companyZip)
      setValue("companyZip", data.companyData?.companyZip)
    if (data?.companyData?.companyCity)
      setValue("companyCity", data.companyData?.companyCity)
    if (data?.companyData?.companyAddressAddition)
      setValue(
        "companyAddressAddition",
        data.companyData?.companyAddressAddition
      )
    if (data?.companyData?.companyVAT)
      setValue("companyVAT", data.companyData?.companyVAT)
    if (data?.companyData?.companyVATFree)
      setValue("companyVATFree", data.companyData?.companyVATFree)
  }, [data?.companyData, setValue])

  const trackChange = () => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || []
      dataLayer.push({
        event: "newCompanyData",
      })
    }
  }
  const toggleVatFree = () => {
    setValue("companyVATFree", !getValues().companyVATFree)
    setValue("companyVAT", "")
  }

  const onSubmit: SubmitHandler<Company> = (data) => {
    updateCompany(data)
    trackChange()
  }

  return (
    <>
      <div className="max-w-md mx-auto m-6 p-6 bg-white rounded-md">
        <span className="text-md text-center ">
          <FontAwesomeIcon icon={faInfoCircle} size="1x" className="mr-2" />
          Bitte prüfe ebenfalls Deine Bankdaten im Reiter "IBAN".
        </span>
      </div>
      <div className="mt-12 mx-auto max-w-md card rounded-md bg-white text-black p-8">
        <h1 className="font-bold text-xl my-1">Firmendaten</h1>
        {isLoading || isMutLoading ? (
          <LoadingSpinner />
        ) : isError ? (
          <>Fehler! Bitte kontaktiere den Support oder versuche es erneut.</>
        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit)} className="">
              <div className="form-control">
                <label className="label">
                  <span className="label-text font-bold">Firmenname*</span>
                </label>
                <input
                  className="input input-bordered"
                  defaultValue={data?.company}
                  {...register("company", { required: true })}
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text font-bold">
                    Straße + Hausnummer*
                  </span>
                </label>
                <input
                  className="input input-bordered"
                  defaultValue={data?.companyData?.companyStreet}
                  {...register("companyStreet", { required: true })}
                />
              </div>
              <div className="grid grid-cols-3 gap-2">
                <div className="form-control">
                  <label className="label">
                    <span className="label-text font-bold">Postleitzahl*</span>
                  </label>
                  <input
                    className="input input-bordered"
                    defaultValue={data?.companyData?.companyZip}
                    {...register("companyZip", { required: true })}
                  />
                </div>
                <div className="form-control col-span-2">
                  <label className="label">
                    <span className="label-text font-bold">Stadt*</span>
                  </label>
                  <input
                    className="input input-bordered"
                    defaultValue={data?.companyData?.companyCity}
                    {...register("companyCity", { required: true })}
                  />
                </div>
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text font-bold">Adresszusatz</span>
                </label>
                <input
                  className="input input-bordered"
                  defaultValue={data?.companyData?.companyAddressAddition}
                  {...register("companyAddressAddition", { required: false })}
                />
              </div>
              <div className="mt-4">
                <p className="font-bold text-md my-1">Steuerliche Angaben</p>
                <p className="my-1">
                  Die Abrechnung erfolgt bei Firmenkunden im Wege der
                  Gutschrift. Die Prämienhöhe versteht sich hier netto zzgl.
                  Umsatzsteuer.
                  <br />
                  Bitte gib an, ob Deine Firma umsatzsteuerbefreit ist. Dann
                  erhältst du Deinen Gutschrift-Beleg netto ohne Umsatzsteuer.
                </p>
              </div>
              <div className="card bordered">
                <div className="form-control">
                  <label className="cursor-pointer label">
                    <div className="w-10 mr-4">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-primary"
                        checked={watch().companyVATFree}
                        onClick={toggleVatFree}
                        {...register("companyVATFree")}
                      />
                    </div>
                    <span className="label-text">
                      Umsatzsteuerfrei gem. § 19 UStG (Kleinunternehmerregelung)
                    </span>
                  </label>
                </div>
              </div>
              {!watch().companyVATFree && (
                <div className="form-control">
                  <label className="label">
                    <span className="label-text font-bold">
                      Steuernummer oder USt-ID*
                    </span>
                  </label>
                  <input
                    className="input input-bordered"
                    defaultValue={data?.companyData?.companyVAT}
                    {...register("companyVAT", {
                      required: true,
                    })}
                  />
                </div>
              )}

              {(errors.company ||
                errors.companyStreet ||
                errors.companyZip ||
                errors.companyCity ||
                errors.companyAddressAddition ||
                errors.companyVAT) && (
                <p className="text-red-500 my-4">
                  Bitte füll alle Pflichtfelder (mit * markiert) aus.
                </p>
              )}
              {isMutError && (
                <p className="text-red-500 my-4">
                  {mutError.data.msg
                    ? mutError.data.msg
                    : "Leider ist ein Fehler aufgetreten. Bitte versuche es erneut."}
                </p>
              )}
              {isMutSuccess && (
                <p className="text-green-500 my-4">Erfolgreich gespeichert.</p>
              )}

              <Input
                text={data?.companyData?.companyCity ? "Ändern" : "Speichern"}
                className="w-full"
              />
            </form>
          </>
        )}
      </div>
    </>
  )
}
export default Firmendaten
