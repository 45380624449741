import React from "react"
import queryString from "query-string"
import { navigate } from "gatsby"
import { setAuthState, User } from "../../state/authSlice"
import { useAppDispatch } from "../../state/createStore"
import { RouteComponentProps } from "@reach/router"
const FromEmail = (props: RouteComponentProps) => {
  const dispatch = useAppDispatch()
  React.useEffect(() => {
    const utmParams = queryString.parse(props.location.search)
    if (utmParams.token && typeof utmParams.token === "string") {
      const user: User = { token: utmParams.token, userType: "customer" }
      dispatch(setAuthState(user))
      navigate(`/kundenbereich`)
    }
  }, [])
  return (
    <>
      <h1>Fehler, kein Token gefunden.</h1>
    </>
  )
}
export default FromEmail
