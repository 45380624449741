import React from "react"
import { RouteComponentProps } from "@reach/router"
import { useCustomerQuery } from "../../services/smartificateApi"
import Layout from "./Layout"
import LoadingSpinner from "../LoadingSpinner"
import PayoutProblemNotice from "./components/PayoutProblemNotice"

const Home = (props: RouteComponentProps) => {
  const { data: customer,isLoading, isError } = useCustomerQuery({})

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : isError || !customer ? (
        <p className="text-white text-center">
          Es ist leider ein Fehler aufgetreten. Bitte wende Dich an den Support.
        </p>
      ) : (
        <Layout>
          <>
          {customer.payoutProblemReminderDate !== undefined && <PayoutProblemNotice />}
          <div className="mt-12 mx-auto max-w-4xl card rounded bg-white text-black px-8 py-4">
            <h1 className="card-title">
              {customer.company ? (
                customer.company
              ) : (
                <>
                  {customer.firstName} {customer.lastName}
                </>
              )}
            </h1>
            {customer.company && (
              <h3 className="tex-md">
                <b>Ansprechpartner:</b> {customer.firstName} {customer.lastName}
              </h3>
            )}
            <h3 className="tex-md">
              <b>E-Mail:</b> {customer.email}
            </h3>
            {customer.registrationDate && (
              <h3 className="tex-md">
                <b>Kunde seit:</b>{" "}
                {new Date(customer.registrationDate).toLocaleDateString("de-DE")}
              </h3>
            )}
          </div>
          </>
         
        </Layout>
      )}
    </>
  )
}
export default Home
