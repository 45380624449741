import React from "react"
import { twMerge } from "tailwind-merge"

const Submit = (props: {
  text: string
  className?: string
  secondary?: boolean
  disabled?: boolean
}) => (
  <input
    className={twMerge(
      `
    mx-4 my-3 mx-0 py-4 px-8 bg-primary tracking-tight font-bold rounded-md  border-primary text-primary-content cursor-pointer
    md:hover:border-primary md:hover:shadow-lg md:hover:bg-primary md:hover:scale-105
    transform transition duration-300 ease-in-out`,

      `${props.secondary ? "bg-transparent border text-white" : ""}`,
      props.className
    )}
    type="submit"
    disabled={props.disabled}
    value={props.text}
  />
)

export default Submit
